var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "not-found"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-center mt-16"
  }, [_c('h1', {
    staticClass: "text-h3 mb-4 white"
  }, [_vm._v("Erro 404")]), _c('h3', {
    staticClass: "subheading font-weight-light mb-3 white"
  }, [_vm._v(" Infelizmente nós não encontramos a página que você procura 😢 ")]), _c('br'), _c('v-btn', {
    attrs: {
      "rounded": "",
      "color": "primary",
      "to": {
        path: '/'
      }
    }
  }, [_vm._v(" Voltar para o Início ")])], 1)], 1), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "not-found-img-wapper d-flex justify-center align-end"
  }, [_c('v-img', {
    staticClass: "not-found-img",
    attrs: {
      "src": "/img/404.png"
    }
  })], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }