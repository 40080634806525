<template>
  <v-container class="not-found">
    <v-row>
      <v-col class="text-center mt-16">
        <h1 class="text-h3 mb-4 white">Erro 404</h1>
        <h3 class="subheading font-weight-light mb-3 white">
          Infelizmente nós não encontramos a página que você procura 😢
        </h3>
        <br>
        <v-btn rounded color="primary" :to="{ path: '/' }">
          Voltar para o Início
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="not-found-img-wapper d-flex justify-center align-end">
          <v-img class="not-found-img" src="/img/404.png"></v-img>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.not-found {
  min-height: 100vh !important;
  .not-found-img-wapper {
    margin-top: 75px;
    width: 100%;
    height: 100%;

    .not-found-img {
      max-width: 50%;
      margin-bottom: 25px;
    }

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      .not-found-img {
        max-width: 30%;
      }
    }

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      .not-found-img {
        max-width: 300px;
      }
    }
  }
}
</style>